<template>

  

    <b-modal
            ref="modalGenerarPayQR"
            centered
            hide-footer
            size="sm"
            hideHeaderClose
                hideHeader
            @hidden="closeModalPay"
          >
            <div class="d-block text-center">
              <b-form
                autocomplete="off"
                @submit.prevent="onSubmit"
                v-if="generarPay"
                
              >

              <b-row>

                <b-col
                                    cols="12"
                                      sm="12"
                                      md="12"
                                      lg="12"
                                      xl="12"
                                    >

                                    

                                                <p
                                        class="textLabel"
                                        style="text-align: left;margin-top: 0px;margin-bottom: 5px;"
                                      >
                                        Seleccione el tipo de usuario (*):</p>



                  </b-col>


                <b-col
                              cols="6"
                                sm="6"
                                md="6"
                                lg="6"
                                xl="6"
                              >

                            <div class="inputGroup">



                            <input id="eqpay" name="radio" @click="onChange('eqpay')" :checked="checked"  value="eqpay" type="radio"/>
                            <label for="eqpay"> 

                              Usuario de EQCoop</label>
                            </div>

                            </b-col>

                            <b-col
                              cols="6"
                                sm="6"
                                md="6"
                                lg="6"
                                xl="6"
                                style="margin-bottom:10px"
                              >

                            <div class="inputGroup">



                            <input id="externo" name="radio" @click="onChange('externo')"  value="externo" type="radio"/>
                            <label for="externo"> 

                              Usuario externo</label>
                            </div>

                            </b-col>

                <b-col
                                    cols="12"
                                      sm="12"
                                      md="12"
                                      lg="12"
                                      xl="12"
                                    >

                                    

                                                <p
                                        class="textLabel"
                                        style="text-align: center;margin-top: 0px;margin-bottom: 5px;"
                                      >
                                        Ingrese la cantidad a pagar (*):</p>

                                        <AutoNumericVue
                                        v-model="mount"
                                        required
                                        :readonly="isDisabled"
                                        type="text" inputmode="decimal"
                                        @blur="onBlurMount"
                                        class="autonumeric_input"
                                        :options="json_opciones"
                                        :placeholder="'$0.00'"
                                    ></AutoNumericVue>

                                    </b-col>

                                    <b-col
                                      sm="12"
                                      md="12"
                                      lg="12"
                                      xl="12"
                                    >
                                        <p class="textLabel" style="text-align: left;margin-top: 0px;margin-bottom: 5px;">
                                          Descripción del pago (Opcional):</p>

                                          <b-form-textarea
                                          v-model="description"
                                          placeholder="Ingrese una descripción corta del pago (Opcional)"
                                          rows="3"
                                          no-resize
                                          :readonly="isDisabled"
                                          :state="description.length <= maxChar"
                                          class="char-textarea"
                                          :class="description.length >= maxChar ? 'text-danger' : ''"
                                        />
                                        <small
                                          class="textarea-counter-value float-left"
                                          :class="description.length >= maxChar ? 'bg-danger' : ''"
                                        >
                                          <span class="char-count">{{ description.length }}</span> / {{ maxChar }}
                                        </small>

                                    </b-col>
                                    

                            

                <b-col
                                cols="12"
                                  sm="12"
                                  md="12"
                                  lg="12"
                                  xl="12"

                                  style="margin-top:15px;justify-content: space-between;display: flex"
                                >

                                <b-button
                                  style="margin-right:10px"
                                  variant="outline-secondary"
                                 
                                  
                                  @click="cancelar()">

                                   Cancelar
                                    

                                  </b-button>

                                  <b-button
                                    id="btn_generar"
                                    ref="btn_generar"
                                    type="submit"
                                    variant="primary"
                                    :disabled="isDisabled"
                                  >

                                    Generar QR

                                  </b-button>


                        

                                </b-col>
              </b-row>
      
      </b-form>

      <b-row v-else>

          <b-col
            cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"

              style="padding:0px"

              v-if="isPayEpay"
            >

            <b-row>


              <b-col
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                style="margin-bottom:10px"
               >

               <b-alert
              variant="primary"
              show
            >
              <div class="alert-body" style="text-align: center;">
                <span style="font-weight: bold;"> Enseña el siguiente código QR a tu cliente. Este código QR solo puede ser escaneado por la app de EQCoop.</span>
              </div>
            </b-alert>


            </b-col>


            <b-col
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
               >


               <vue-qr  style="width: 250px !important;" :callback="getDataURL" :margin="margin" logoBackgroundColor="#fff" backgroundColor="#fff"  :logoScale="logoScale" :logoMargin="logoMargin" :logoCornerRadius="logoCornerRadius" :text="scanData" :correctLevel="correctLevel" :size="size"></vue-qr>
              

               </b-col>


               <b-col
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                style="margin-top:10px"
               >

               <b-button
                block
                variant="primary"
                @click="atras"
              >

                Generar otro código QR

              </b-button>

               </b-col>

              
            </b-row>



               </b-col>

               <b-col
                cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"

                  v-else
                >


            </b-col>

       </b-row>
            </div>
      
          </b-modal>
          
          </template>
          
          <script>
          import {
            BButton, BRow, VBPopover, BCol, BCard,BFormTextarea, BForm, BAlert
          } from 'bootstrap-vue'
          
          import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';
          import VueQr from 'vue-qr'


          export default {
            components: {
                BFormTextarea,
                BForm,
                VueQr,
                BAlert,
              BButton,
              BRow,
              BCol,
              BCard,
              AutoNumericVue,
            },
            directives: {
              'b-popover': VBPopover,
            },
            props: ['idLocal', 'tokenAuth', 'userId'],
            data() {
          
          
              return {
                isDisabled:false,
                mount:"",
                json_opciones:{},
                description:"",
                maxChar: 75,
                checked:true,
                typeUser:"eqpay",
                generarPay:true,
                isPayEpay:false,
                dataUrl:"",
                margin:50,
                  logoScale:0.3,
                        logoMargin: 1,
                        logoCornerRadius:100,
                  correctLevel:3,
                  size:800,
                  scanData:"",
              }
            },
            computed: {
          
            },
            watch: {
          
            },
            mounted() {
              this.json_opciones={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 0, maximumValue:1000000, modifyValueOnWheel: false };

           
            this.$refs.modalGenerarPayQR.show()
            },
            methods: {

              atras(){
                this.generarPay=true;
                this.isPayEpay=false;
                this.mount="";
                this.description="";

              },

              getDataURL(dataUrl,id){

  

                this.dataUrl= dataUrl;

            
                },


              onChange(event) {

              this.typeUser= event;
 
 
         },

              onSubmit(event){
      event.preventDefault();

      if(Number(this.description.length) > Number(this.maxChar)){

            this.description="";

              this.$toast.error("El texto de la descripción sobrepasa el límite permitido de 75 caracteres", {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })

            }else{

              this.generarPay=false;


              if(this.typeUser === "eqpay"){

                if(this.description === ""){
                  this.scanData= this.idLocal+";payLocal;"+this.mount.toFixed(2);
                }else{
                  this.scanData= this.idLocal+";payLocal;"+this.mount.toFixed(2)+";"+this.description;
                }

               
                this.isPayEpay=true;

              }else{
                  //es de tipo externo
                this.$refs.modalGenerarPayQR.hide()
              this.$toast.error("Funcionalidad no disponible por el momento", {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })

                

            


              }


            }

           

    },


              cancelar(){
                this.$refs.modalGenerarPayQR.hide()
              },
    
                  
                closeModalPay(){
                                this.$eventBus.$emit('reiniciarModalPay')
                            },

                            
             onBlurMount(){



              if(this.mount == null || this.mount == "null" || this.mount ==""){
                this.mount ="";



                }else{

                  if(Number(this.mount) < Number("1")){

                    this.mount ="";
                          this.$toast.error("La cantidad a pagar debe ser mayor a $1.00 USD" , {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                              })



                  }

              

                  


                }

              },
      
           
             
          
            },
          }
          </script>
          
          <style lang="scss">
          
          
          
          </style>
          